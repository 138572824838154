import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import NotificationContext from './NotificationContext';
import { Routes, Route } from 'react-router-dom';
import { Listings } from './Listings';
import { Products } from './Products';
import { Purchases } from './Purchases';
import { Sales } from './Sales';

class ContentContainer extends Component {  
  render() {
    const user = this.props.user;

    return (
      <div className="mt-5 pt-4">
        <ToastContainer />
        <NotificationContext.Consumer>
          { notification => {
            if (notification.list.length > 0) {
              notification.list.forEach(alert => {
                toast(alert.message, {
                  type: alert.type,
                  onClose: () => notification.dismiss(alert)
                });
              });
            }
            return null;
          }}
        </NotificationContext.Consumer>
        <Routes>
          {user && (
            <Route 
              path="/" 
              element={
                <Purchases 
                  results={this.props.results} 
                  search={this.props.search}
                  index="order_purchase_item"
                  sort="history.purchased"
                />
              } 
            />
          )}
          
          {user && (
            <Route 
              path="/purchases" 
              element={
                <Purchases 
                  results={this.props.results} 
                  search={this.props.search} 
                  index="order_purchase_item"
                  sort="history.purchased"
                />
              } 
            />
          )}
          
          {user && (
            <Route 
              path="/sales" 
              element={
                <Sales 
                  results={this.props.results} 
                  search={this.props.search} 
                  index="order_sale"
                  sort="ordered"
                />
              } 
            />
          )}
          
          {user && (
            <Route 
              path="/listings/:channel" 
              element={
                <Listings 
                  results={this.props.results} 
                  search={this.props.search} 
                  index="listing"
                />
              } 
            />
          )}
          
          {user && (
            <Route 
              path="/products" 
              element={
                <Products 
                  results={this.props.results} 
                  search={this.props.search} 
                  index="product"
                />
              } 
            />
          )}
        </Routes>
      </div>  
    );
  }
}

export { ContentContainer };
