import React from 'react';
import { ResultsComponent, getProduct, getListing } from './AllThing';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import { Pager } from './Pager';
import { SaleRow } from './SaleRow';
import { SaleEdit } from './SaleEdit';
import { ReturnEdit } from './ReturnEdit';
import { ProductEdit } from './ProductEdit';
import { ListingEdit } from './ListingEdit';
import { StockCommitEdit } from './StockCommitEdit';

class Sales extends ResultsComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state
    };
  }

  openReturnEditModal = (row, modalCallback) => {
    const showModal = modalCallback || this.showModal;

    const modalContent = (
      <ReturnEdit 
        row={ row } 
        hideModal={ this.hideModal }
        setModalButtons={ this.setModalButtons }
      />
    );
    
    showModal(`Return for Order # ${row.number}`, modalContent);
  }

  openProductEditModal = async (productId, modalCallback) => {
    const showModal = modalCallback || this.showModal;
    const productSnapshot = await getProduct(productId);
    const product = { ...productSnapshot.val(), key: productId };

    const modalContent = (
      <ProductEdit 
        row={product}
        search={this.props.search}
        hideModal={this.hideModal}
        setModalButtons={this.setModalButtons} />
    );
    
    showModal(`Edit Product #${productId}`, modalContent);
  }

  openListingEditModal = async (listingId, modalCallback) => {
    const showModal = modalCallback || this.showModal;
    const listingSnapshot = await getListing(listingId);
    const listing = { ...listingSnapshot.val(), key: listingId };

    const modalContent = (
      <ListingEdit 
        row={listing}
        search={this.props.search}
        hideModal={this.hideModal}
        setModalButtons={this.setModalButtons} />
    );
    
    showModal(`Edit Listing #${listingId}`, modalContent);
  }

  openSaleEditModal = (row, modalCallback) => {
    const showModal = modalCallback || this.showModal;
    if (row.status.toLowerCase() === "pending" || row.status.toLowerCase().startsWith('cancel')) {
      return; // Don't open the modal for pending or cancelled orders
    }

    const modalContent = (
      <SaleEdit 
        row={row}
        search={this.props.search}
        hideModal={this.hideModal}
        setModalButtons={this.setModalButtons}
        openListingEditModal={this.openListingEditModal}
        openProductEditModal={this.openProductEditModal}
        openStockCommitEditModal={this.openStockCommitEditModal}
      />
    );
    
    showModal(`Edit Order # ${row.key}`, modalContent);
  }

  openStockCommitEditModal = async (productId, modalCallback) => {
    const showModal = modalCallback || this.showModal;
    const productSnapshot = await getProduct(productId);
    const product = { ...productSnapshot.val(), key: productId };

    const modalContent = (
      <StockCommitEdit 
        product={product}
        search={this.props.search}
        hideModal={this.hideModal}
        setModalButtons={this.setModalButtons}
      />
    );
    
    showModal(`Edit Stock for Product #${productId}`, modalContent);
  }

  render() {
    let search = this.props.search;
    let saleRows = null;
    
    if (search.location === window.location.href) {
      saleRows = this.props.results.map(row =>
        <SaleRow 
          key={row.key} 
          row={row} 
          search={this.search}
          index={this.index}
          editRow={this.editRow}
          editListing={this.editListing}
          showModal={this.showModal}
          defaultChannel={this.state.defaultChannel}
          defaultCondition={this.state.defaultCondition} 
          openListingEditModal={this.openListingEditModal}
          openProductEditModal={this.openProductEditModal}
          openReturnEditModal={this.openReturnEditModal}
          openSaleEditModal={this.openSaleEditModal}
          openStockCommitEditModal={this.openStockCommitEditModal}
        /> 
      );
    } else {
      saleRows = <Row><Col>Loading Data ...</Col></Row>;
    }
    
    return (
      <div>
        { this.renderModal() }
        <Container fluid>
          <Row>
            <Col sm={3} md={2}>
              <Card>
                <Card.Header><strong>Sale Filters</strong></Card.Header>
                <Card.Body>
                  <Form.Group>
                    <Form.Label>Sale Status</Form.Label>
                    <Form.Control 
                      as="select"
                      name="status"
                      defaultValue={this.state.filters.status}
                      onChange={e => this.handleSelect(e, 'filters')}>
                        <option value="">All Sales</option>
                        <option value={ `
                          _exists_:return.tracking
                        ` }>Return</option>
                        <option value={ `
                          NOT _exists_:item.product
                          AND NOT channel:"ShipStation" 
                          AND NOT channel:"20140"
                        ` }>NOT LINKED</option>
                        <option value={`
                          shipping.cutoff:<=${new Date().toISOString().split('T')[0]}
                          AND NOT _exists_:tracking
                          AND NOT status:cancel*
                        `}>MUST SHIP TODAY</option>
                    </Form.Control>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={9} md={10}>
              <Row className="mb-2 font-weight-bold">
                <Col xs={12} md={1}>Age</Col>
                <Col xs={6} md={1}>Ship By</Col>
                <Col xs={12} md={2}>Marketplace</Col>
                <Col xs={12} md={4}>Order Items</Col>
                <Col xs={6} md={1}>Customer</Col>
              </Row>
              {saleRows}
              <Pager
                pageCount={search.pages} 
                activePage={search.page} 
                onSelect={search.setPage} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export { Sales };
