import React from 'react';
import { ResultsComponent, escapeSearch } from './AllThing';
import { Container, Row, Col, Table, Card, Form, Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { Pager } from './Pager';
import { PurchaseRow } from './PurchaseRow';
import { PurchaseEdit } from './PurchaseEdit';

class Purchases extends ResultsComponent {
  
  constructor(props) {
    super(props);    
    
    this.editPurchase = this.editPurchase.bind(this);
    
    this.search = this.props.search;
    
    // Query transformations.  Reformats tracking number barcode scans so that
    // they appear in the format associated with the orders
    this.search.transformQuery(query => {
      const usps = query.match(/^[0-9]{3}[0-9]{5}([0-9]{22})$/);
      const fedex = query.match(/^[0-9]{22}([0-9]{12})$/);
      
      if (usps) return usps[1];
      else if (fedex) return fedex[1];
      
      return query;
    });
  }
  
  editPurchase(row = { }) {      
    const receipt = {
      id: row.id || '',
      key: row.key,
      type: 'Purchase',
      source: row.link && row.link.indexOf('ebay') >= 0 ? 'eBay' : '',
      received: row.received || new Date().toJSON(),
      tracking: row.tracking || '',
      images: row.images,
      condition: row.condition,
      buyerNotes: row.buyerNotes,
      notes: row.notes || '',
      description: row.description || '',
      manifest: row.manifest || [{ 
        type: 'order',
        quantity: row.quantity || 1, 
        lots: row.quantity || 1,
        lotSize: 1,
        condition: 'likenew',
        lotPrice: row.price || 0.00,
        price: row.price || 0.00,
        notes: row.notes || ''
      }]
    };
    
    const modalContent = (
      <PurchaseEdit 
        row={ receipt } 
        title={ row.title }
        search={ this.props.search }
        index={ this.props.index }
        addProduct={ this.addProduct }
        hideModal={ this.hideModal }
        setModalButtons={ this.setModalButtons } />
    );
    
    this.showModal(`Edit Purchase (${ row.key || 'NEW' })`, modalContent);
  }

  render() {
    const search = this.props.search;
    let tableData = null;
    
    const twoWeeksAgoDate = new Date();
    twoWeeksAgoDate.setDate(-14);
    const twoWeeksAgo = escapeSearch(twoWeeksAgoDate.toJSON());
    
    if (search.location === window.location.href) {
      tableData = this.props.results.map(row =>
        <PurchaseRow 
          key={ row.key } 
          row={ row } 
          index={ this.index }
          showModal={ this.showModal }
          editPurchase={ this.editPurchase } />
      );
    } else {
      tableData = <tr><td colSpan="5">Loading Data ...</td></tr>;
    }
  
    return (
      <div>
        { this.renderModal() }
        <Container fluid>
          <Row>
            <Col sm={3} md={2}>
              <Card>
                <Card.Header><strong>Purchase Filters</strong></Card.Header>
                <Card.Body>
                  <Form.Group>
                    <Form.Label>Purchase Status</Form.Label>
                    <Form.Control 
                      as="select"
                      name="status"
                      defaultValue={this.state.filters.status}
                      onChange={e => this.handleSelect(e, 'filters')}>
                        <option value="">All Purchases</option>
                        <option value="NOT _exists_:manifest">NOT RECORDED</option>
                        <option value={ `
                          NOT _exists_:history.delivered
                          AND NOT manifest.type:receipt 
                          AND NOT _exists_:history.cancelled
                          AND history.purchased:<${ twoWeeksAgo }
                        ` }>NOT RECEIVED</option>
                        <option value="manifest.type:receipt AND NOT _exists_:manifest.committed">NOT COMMITTED</option>
                        <option value="(manifest.type:receipt AND manifest.condition:return) OR history.returned:return*">Returns</option>
                        <option value={`
                          ((manifest.type:receipt AND manifest.condition:return) OR history.returned:return*) AND
                          (NOT _exists_:manifest.return) AND
                          (NOT _exists_:history.refunded)
                        `}>
                          Returns / Not Shipped
                        </option>
                        <option value={`
                          ((manifest.type:receipt AND manifest.condition:return) OR history.returned:return*) AND
                          (_exists_:manifest.return) AND
                          (NOT _exists_:history.refunded)
                        `}>
                          Returned / Not Refunded
                        </option>
                    </Form.Control>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={9} md={10}>
              <Table>
                <thead>
                  <tr>
                    <th><a href="#x" onClick={e => this.sortResults(e, 'history.purchased')}>Purchased</a></th>
                    <th>Status</th>
                    <th>Source</th>
                    <th>Title</th>
                    <th>From</th>
                    <th>Inventory</th>
                    <th className="text-right">
                      <Button className="product-add" variant="info" size="sm" title="Add New Purchase" onClick={e => this.editPurchase()}>
                        <FaPlus />
                      </Button>
                    </th>
                  </tr>
                </thead>
                <tbody>{tableData}</tbody>
              </Table>
              <Pager
                pageCount={this.search.pages} 
                activePage={this.search.page} 
                onSelect={this.search.setPage} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export { Purchases };
