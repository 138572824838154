import React from 'react';
import axios from 'axios';
import { Form, Image, Container, Row, Col, Badge } from 'react-bootstrap';
import { FaPencilAlt, FaFileInvoiceDollar, FaLongArrowAltRight, FaMinusCircle, FaExclamationTriangle, FaBoxes, FaRegCalendarAlt, FaMagic } from 'react-icons/fa';
import { RowComponent, getChannelIcon, getListing, getListingLinks, getProduct, formatDate } from './AllThing';
import { StockWarehouse } from './StockWarehouse';


class ListingRow extends RowComponent {
  constructor(props) {
    super(props);    
    
    this.state.product = {};
    this.state.listings = {};
    
    this.identifyListing = this.identifyListing.bind(this);
    
    if (this.state.row && this.state.row.image) {
      if (!this.state.row.image.length) {
        this.state.row.image = [ this.state.row.image ];
      }
    }
    
    // onData callback for side-loading product data
    this.onData(data => this.loadProduct());    
  }
  
  componentDidUpdate(prevProps, prevState) {
    const oldRow = prevState.row;
    const newRow = this.state.row;
    
    if (oldRow.product !== newRow.product) {
      console.log('Product Change.  Component re-use detected.');
    }
  }
  
  loadProduct() {
    const row = this.state.row;
    
    if (row.product) {
      getProduct(row.product, snap => {
        const product = { ...snap.val(), key: snap.key };
      
        this.addDataRef(snap.ref);      
        this.setStateSafe({ product });
        this.populateListings(product);
      });
    }
  }
  
  async identifyListing(asin) {
    const { row, listings } = this.state;
    const { linkListing } = this.props;
  
    const endpoint = 'https://dev.newfanglednetworks.com:8080/identifyListing';  
    const response = await axios.get(`${ endpoint }?asin=${ asin }`);
    
    if (response?.data?.listingUpdate) {
      console.log(response.data.listingUpdate, '/listing', row.key);
      await this.commit(response.data.listingUpdate, '/listing', row.key);
    }
    
    if (response?.data?.results?.length && linkListing) {
      const product = response.data.results[0].key;
      const listing = { ...row, ...response.data.listingUpdate, product };
      const productSnap = await getProduct(product);
      
      linkListing(listing, productSnap.val(), listings);
    }
  }  
  
  // @TODO Had to stop using the open-listener version of getListing.  It was
  // erratically causing firebase references to go blank.  At some point we
  // should figure out why that was happening.  Too many listeners opened
  // at once? Maybe a bug in firebase version?  Not spending more time on this
  // now as we don't *really* need product listing maps to live update for
  // this view....
  populateListings(product) {
    const listings = { };

    Object.keys(product.listing || {}).forEach(id => 
      getListing(id, snap => {
        this.addDataRef(snap.ref);

        this.setStateSafe({
          listings: {
            ...this.state.listings,
            [snap.key]: snap.val()
          }
        });
      })
    );
/*  
    Promise.all(
      Object.keys(product.listing || { }).map(id =>
        new Promise((resolve, reject) =>
          getListing(id).then(snap => 
            resolve({ ...snap.val(), key: snap.key })
          )
        )
      )
    )
    .then(values => {
      values.map(listing => listings[ listing.key ] = listing);
      this.setStateSafe({ listings });
    });
*/
  }    
  
  render() {
    const row = this.state.row;
    const product = this.state.product;
    const listings = this.state.listings;
    const links = getListingLinks(row, product).urls;
    const { editListing, editStock, linkListing } = this.props;
    const images = [];
    
    if (row.image) {
      if (typeof row.image === 'object' && row.image.length >= 0) {
        images.push(...row.image);
      } else if (typeof row.image === 'object') {
        images.push(...Object.values(row.image));
      } else {
        images.push(row.image);
      }
    } else {
      images.push('/img/placeholder.svg');
    }
    
    return (
      <div className="listing-row">
        { this.renderModal() }
        <Container fluid>
          <Row>
            <Col sm={ 5 } className="listing-detail">
              <Form.Check
                className="float-left"
                checked={ this.props.isChecked(row.key) } 
                type="checkbox" 
                onChange={() => this.props.handleCheck(row.key) } />
              { images.length &&
                <div className="image-carousel float-left">
                  <div className="image">
                    <Image 
                      fluid 
                      src={ images[0] } 
                      className="clickable"
                      onClick={() => this.showImages(images)} />              
                  </div>
                </div>
              }
              <div className="channel">
                <span>
                  <a href={ links[1] || links[0] } target="_blank" 
                    rel="noopener noreferrer">
                      { getChannelIcon(row.channel) }
                  </a>
                </span>
                <strong>{ row.channel }</strong>
                { row.active === true && row.suppressed === true && 
                  <FaExclamationTriangle className="text-warning" />
                }
                { 'active' in row && row.active === false &&
                  <FaMinusCircle className="text-danger" />
                }

                <FaMagic 
                  className="clickable"
                  onClick={ () => this.identifyListing(row.parentId) } />                
              </div>
              <div className="title text-truncate">
                <small>
                  { row.model && 
                    <strong>{ row.model } </strong>
                  }
                  <a href={ links[0] } target="_blank" 
                    rel="noopener noreferrer" className="text-muted">
                      { row.title }
                  </a>
                </small>
              </div>
              <div className="channel availability">
                <span><FaBoxes />{ row.channelStock }</span>
                <span><FaFileInvoiceDollar />{ row.channelPrice }</span>
                <span>
                  <FaRegCalendarAlt />          
                  {formatDate(row.created, 'M/D/YY')}
                </span>
              </div>
            </Col>
            <Col sm={ 1 }>
              <span className="condition">
                { row.channelCondition && row.channelCondition.toUpperCase() }
              </span>
              <FaLongArrowAltRight 
                className="clickable" 
                onClick={ () => linkListing(row, product, listings) } />
              <span className="condition">
                { row.condition && row.condition.toUpperCase() }
              </span>
            </Col>
            <Col sm={ 5 } className="listing-detail">
              { product.image &&
                <div className="image-carousel float-left">
                  <div className="image">
                    <Image 
                      fluid 
                      src={ product.image[0] } 
                      className="clickable"
                      onClick={() => this.showImages(product.image)} />              
                  </div>
                </div>
              }
              <div className="channel">
                <Badge variant="secondary" bg="secondary">
                  { row.condition && row.condition.toUpperCase() }
                </Badge>
                <strong>{ product.vendor } { product.model }</strong>
              </div>
              <div className="title text-truncate">
                <small className="text-muted">
                  { product.title }
                </small>
              </div>
              <div className="availability">
                { product && product.model &&
                <StockWarehouse 
                  listings={ listings }
                  product={ product }
                  stock={ product.stock }
                  editListing={ editListing }
                  editStock={ () => editStock(product) } />     
                }
              </div>
            </Col>
            <Col sm={ 1 } className="text-right">
              <FaPencilAlt onClick={e => editListing(row)} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export { ListingRow };
