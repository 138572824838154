import React from 'react';
import { OverlayTrigger, Button, Row, Col, Tooltip, Popover } from 'react-bootstrap';
import { FaUndo, FaHome, FaBuilding } from 'react-icons/fa';
import { RowComponent, getChannelIcon, getListing, getContact, getProduct } from './AllThing';

class SaleRow extends RowComponent {
  constructor(props) {
    super(props);

    // onData callback for side-loading listing data
    this.onData(data => {    
      this.loadContactDetails(data);
      this.loadItemDetails(data);
    });    

  }


  calculateOrderAge(orderDate) {
    const now = new Date();
    const ordered = new Date(orderDate);
    
    // Convert UTC to EST
    const estOffset = -5 * 60; // EST is UTC-5
    const estNow = new Date(now.getTime() + estOffset * 60000);
    const estOrdered = new Date(ordered.getTime() + estOffset * 60000);

    const diffTime = Math.abs(estNow - estOrdered);
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    const diffDays = Math.floor(diffHours / 24);

    if (diffHours < 24) {
      return `${diffHours} hour${diffHours !== 1 ? 's' : ''}`;
    } else {
      return `${diffDays} day${diffDays !== 1 ? 's' : ''}`;
    }
  }

  formatDate(date) {
    return new Date(date).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: '2-digit' });
  }

  loadContactDetails = async (row) => {
    if (!row?.ship?.contact) return;
    const contact = await getContact(row.ship.contact);
    this.setState({ contact });
  }

  loadItemDetails = async (row) => {
    const updatedItems = {};

    for (const [id, item] of Object.entries(row.item || {})) {
      const productSnapshot = await getProduct(item.product);
      const product = productSnapshot.val();
      let listing = {};

      // Find the listing for this channel and item
      for (const listingId of Object.keys(product?.listing || {})) {
        const productListing = product.listing[listingId];

        if (productListing.channel === row.channel && productListing.id.includes(item.channelId)) {
          const listingSnapshot = await getListing(listingId);
          listing = { ...listingSnapshot.val(), key: listingSnapshot.key };
          break; // Exit the loop once we find the matching listing
        }
      }

      updatedItems[id] = {
        ...item,
        ...product,
        listing
      };
    }

    this.setState(prevState => ({
      row: {
        ...prevState.row,
        item: updatedItems
      }
    }));
  }


  render() {
    let row = this.state.row;
    row.history = row.history || {};
    row.shipping = row.shipping || {};
    row.ship = row.ship || {};

    console.log(row.item);

    const contact = this.state.contact;
    const shipTo = { ...row.ship, ...contact?.location?.[row.ship.location] };

    // Determine if the order is canceled
    const isCanceled = row.status.toLowerCase().startsWith('cancel');

    return (
      <Row className={`mb-2 align-items-center ${isCanceled ? 'text-muted' : ''}`}>
        <Col xs={12} md={1}>{this.calculateOrderAge(row.ordered)}</Col>
        <Col xs={6} md={1}>{row.shipping.cutoff && !isNaN(new Date(row.shipping.cutoff).getTime()) ? this.formatDate(row.shipping.cutoff) : ''}</Col>
        <Col xs={12} md={2}>
          <span>{getChannelIcon(row.channel)}</span>
          <span 
            className="ml-1"
            onClick={() => this.props.openSaleEditModal(row)} 
            style={{ cursor: row.status.toLowerCase() === "pending" || isCanceled ? 'default' : 'pointer' }}
          >
            {isCanceled ?  row.number || row.channelId : row.number || row.channelId}
          </span>
          {isCanceled && (
            <span className="badge badge-secondary ml-1">
              <span className="small font-weight-bold">CANCELED</span>
            </span>
          )}
          {row.return && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-return-${row.key}`}>
                  {Object.values(row.return)[0]?.classification || 'Return'}
                </Tooltip>
              }
            >
              <Button variant="link" className="p-0 m-0" onClick={() => this.props.openReturnEditModal(row)}>
                <span className="badge badge-warning">
                {' '}<FaUndo size={10} />
                </span>
              </Button>
            </OverlayTrigger>
          )}
          {!isCanceled && new Date(row.shipping.cutoff).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0) 
          && row.status === "awaiting_shipment" && (
            <>
              {' '}<span className="badge badge-warning ml-1">
                <span className="small font-weight-bold">MUST SHIP TODAY</span>
              </span>
            </>
          )}
          {!isCanceled && row.status.toLowerCase() === "pending" && (
            <span className="badge badge-secondary ml-1">
              <span className="small font-weight-bold">PENDING</span>
            </span>
          )}
        </Col>
        <Col xs={12} md={4}>
          {Object.keys(row.item || {}).map(id => 
            <div key={id} className="d-flex align-items-center mb-2">
              <div>
                <div>
                  <span 
                    className="badge badge-secondary mr-1 rounded-circle d-inline-flex align-items-center justify-content-center" 
                    style={{ width: '15px', height: '15px', cursor: 'pointer' }}
                    onClick={() => this.props.openStockCommitEditModal(row.item[id].product)}
                  >
                    <span className="small font-weight-bold" style={{ marginRight: '1px', marginTop: '2px', lineHeight: '15px' }}> 
                      {row.item[id].quantity}
                    </span>
                  </span>
                  {row.item[id].vendor && (
                    <span onClick={() => this.props.openProductEditModal(row.item[id].product)} style={{ cursor: 'pointer' }}>
                      {row.item[id].vendor}{' '}
                    </span>
                  )}                  
                  {row.item[id].model && (
                    <span onClick={() => this.props.openProductEditModal(row.item[id].product)} style={{ cursor: 'pointer' }}>
                      {row.item[id].model}{' '}
                    </span>
                  )}
                  <span 
                    className="small"
                    onClick={() => this.props.openListingEditModal(row.item[id].listing.key)}
                    style={{ cursor: 'pointer' }}
                  >
                    <span className="text-success font-weight-bold"> 
                      {row.item[id].quantity === 1 && `$${row.item[id].price?.toFixed(2) || row.item[id]?.listing?.channelPrice?.toFixed(2)}`}
                      {row.item[id].quantity > 1 && `$${(row.item[id].quantity * (row.item[id].price || row.item[id]?.listing?.channelPrice)).toFixed(2)}`}
                    </span>
                    <span className="text-muted">{row.item[id].quantity > 1 && ` /`}</span>
                    <span className="text-primary">
                      {row.item[id].quantity > 1 && `
                      $${row.item[id].price?.toFixed(2) || row.item[id]?.listing?.channelPrice?.toFixed(2)} ea
                    `}
                    </span>
                  </span>
                  {row.item[id]?.listing?.channelCondition?.toLowerCase()?.startsWith('use') && (
                    <span className="badge badge-warning ml-1">
                      <span className="small font-weight-bold">
                        {row.item[id]?.listing?.channelCondition?.toUpperCase()}
                      </span>
                    </span>
                  )}
                </div>
                <div className="small text-muted">
                  <span>
                    {row.item[id].title && row.item[id].title.length > 50
                      ? `${row.item[id].title.substring(0, 50)}...`
                      : row.item[id].title}
                  </span>
                </div>
              </div>
            </div> 
          )}
        </Col>
        <Col xs={6} md={4}>
          {shipTo.person && (
            <div className="small text-muted">
              {shipTo.residential === false ? <FaBuilding /> : <FaHome />}
              {' '}
              {shipTo.company && `${shipTo.company}`}
              {!shipTo.company && shipTo.person && `${shipTo.person}`}
              <br />
              {shipTo.city}, {shipTo.state} {shipTo.country !== 'US' && shipTo.country}
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

export { SaleRow };
