import React from 'react';
import { EditComponent, getChannelIcon, getListingLinks, getOrder, getProduct, formatDate } from './AllThing';
import { Alert, Container, Row, Col, Image, Badge } from 'react-bootstrap';
import { FaBarcode, FaFileInvoiceDollar, FaLongArrowAltRight, FaMinusCircle, FaExclamationTriangle, FaBoxes, FaHashtag, FaInfoCircle, FaPuzzlePiece, FaRegCalendarAlt } from 'react-icons/fa';
import { ProductEdit } from './ProductEdit';
import { QuickEdit } from './QuickEdit';
import { StockWarehouse } from './StockWarehouse';
import { StockCommitEdit } from './StockCommitEdit';
import { SearchProductResults } from './SearchProductResults';


class ListingLink extends EditComponent {
  constructor(props) {
    super(props);
    
    this.state.product = this.props.product || { };
    this.state.row.condition = this.state.row.condition || 'new';
    this.state.orphanOrders = [ ];
    
    // If product is passed in as a suggestion but isn't yet saved in the 
    // listing, store the product id on the listing so it will save.
    //if (this.state.product.key && !this.state.row.product) {
    //  this.state.row.product = this.state.product.key;
    //}

    // Limit fields that are committed to datastore
    this.saveFields = [ 'product', 'condition' ];    
    
    this.addProduct = this.addProduct.bind(this);
    this.editStock = this.editStock.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.refreshProduct = this.refreshProduct.bind(this);
    this.updateProduct = this.updateProduct.bind(this);
    
    this.getListingOrders();
  }
  
  afterSave(row) {
    const { product, commit, note, orphanOrders } = this.state;
    const updates = { };
    
    const data = {
      commit, note,
      committed: true,
      date: new Date().toJSON(),
      source: 'manual',
      user: this.getUser().uid
    };        
    
    // If manual stock adjustments were made along with linking listing to 
    // product, make the appropriate entry for the manual adjustment in the
    // stock log.
    if (commit) {
      this.commit(data, `stock/${product.key}`);
    }
    
    // ElasticSearch isn't storing the unique ids for order items and is
    // instead storing them as a plain array.  Unfortunately that means
    // we have to query each order to be updated to get the order item id
    // prior to adding the product reference.
    orphanOrders.map(orphan => {   
      if (!orphan.product) return;
      
      getOrder(orphan.key)
        .then(snap => {
          const order = snap.val();
          
          Object.keys(order.item || { }).map(itemId => {
            const item = order.item[ itemId ];
            
            if (item.channelId === this.state.row.childId) {
              this.commitAll({
                [ `order/sale/${ orphan.key }/item/${ itemId }/product` ]: product.key
              });
            }
          });
        });
    });
  }
    
  addProduct() {
    const modalContent = (
      <ProductEdit 
        index="product"
        onClose={ this.updateProduct }
        hideModal={ this.hideModal }
        setModalButtons={ this.setModalButtons } />
    );
    
    this.showModal(`Add Product (New)`, modalContent);
  }
  
  editStock(product) { 
    const modalContent = (
      <StockCommitEdit 
        title={ product.title }
        search={ this.props.search }        
        hideModal={ this.hideModal }
        setModalButtons={ this.setModalButtons }
        onClose={ this.refreshProduct }
        product={ product } />
    );
    
    this.showModal(`Edit Stock`, modalContent);
  }
  
  getListingOrders() {
    const row = this.state.row;
    
    this.search.simpleQuery(
      'order_sale',
      `
        item.channelId:"${ row.childId }"
        AND NOT _exists_:item.product
        AND NOT channel:"ShipStation" 
        AND NOT channel:"20140"
      `
    ).then(results => {
      this.setStateSafe({ orphanOrders: results });
    });
  }
  

  // Exponent notation ^x "boosts" query string search results to be more 
  // relevant.  In this case, we want model matches to show much higher
  // in the results than title matches.
  // @TODO This code is duplicated in PurchaseEdit.  May want to move
  // to single source.
  handleSearch(query, updateOption) {
    return this.props.search
      .simpleQuery('product', 
        `((model:"${query}")^4 
          OR (model:${query}*)^2 
          OR title:${query}*) 
          AND (NOT archived:true)`)
      .then(options => {
        options.map((opt, idx) => 
          getProduct(opt.key)
            .then(snap => updateOption({ ...snap.val(), key: snap.key }, idx)
          )
        );
        
        return options;
      });
  }
  
  linkOrder(idx) {
    const orphanOrders = [ ...this.state.orphanOrders ];

    if (orphanOrders[idx].product) {
      orphanOrders[idx].product = null;
    } else {    
      orphanOrders[idx].product = this.state.product.key;
    }
  
    this.setStateSafe({ orphanOrders });
  }
  
  refreshProduct(pid = this.state.product.key) {
    getProduct(pid)
      .then(snap => this.setState({ product: 
        { ...snap.val(), key: snap.key }
      }));    
  }
  
  updateProduct(product) {
    this.updateRow({ target: { name: 'product', value: product.key } });

    // We only get the product data stored in elasticsearch here, which is 
    // limited.  So we load the full product from firebase.
    this.refreshProduct(product.key);
  }
    
  render() {
    const row = this.state.row;
    const product = this.state.product;
    const links = getListingLinks(row, product).urls;
    const { editListing, listings } = this.props;
    const images = [];
    
    if (row.image) {
      if (Array.isArray(row.image)) {
        images.push(...row.image);
      } else if (typeof row.image === 'object') {
        images.push(...Object.values(row.image));      
      } else {
        images.push(row.image);
      }
    } else {
      images.push('/img/placeholder.svg');
    }
    
    return (
      <form autoComplete="off">
        <div>
          { this.renderModal() }
          <Container className="listing-link" fluid>
            <Row className="listing-row">
              <Col sm={ 5 } className="listing-detail">
                <div className="channel">
                  <Badge variant="secondary" bg="secondary" className="clickable">
                    {  row?.channelCondition?.toUpperCase() }
                  </Badge>
                  <strong>{ row.channelBrand } { row.channelModel }</strong>
                  { row.active === true && row.suppressed === true && 
                    <FaExclamationTriangle className="text-warning" />
                  }
                  { 'active' in row && row.active === false &&
                    <FaMinusCircle className="text-danger" />
                  }
                </div>
                { images.length &&
                  <div className="image-carousel float-left">
                    <div className="image">
                      <Image 
                        fluid 
                        src={ images[0] } 
                        className="clickable"
                        onClick={() => this.showImages(images)} />              
                    </div>
                  </div>
                }
                <div className="title">
                  <span>
                    <a href={ links[1] || links[0] } target="_blank" 
                      rel="noopener noreferrer">
                        { getChannelIcon(row.channel) }
                    </a>
                  </span>
                  <small>
                    { row.model && 
                      <strong>{ row.model } </strong>
                    }
                    <a href={ links[0] } target="_blank" 
                      rel="noopener noreferrer" className="text-muted">
                        { row.title }
                    </a>
                  </small>
                </div>
                <div className="availability">
                  <span><FaBoxes />{ row.channelStock }</span>
                  <span><FaFileInvoiceDollar />{ row.channelPrice }</span>
                  <span>
                    <FaRegCalendarAlt />          
                    {formatDate(row.created, 'M/D/YY')}
                  </span>
                  <span><FaHashtag />{ row.channelPart }</span>                  
                  { row?.channelGTIN?.upc &&
                    <span><FaBarcode />{ row.channelGTIN.upc }</span>                  
                  }
                </div>
              </Col>
              <Col sm={ 2 }>
                <FaLongArrowAltRight />
              </Col>
              <Col sm={ 5 } className="listing-detail">
                <div className="channel">
                  { product && product.model &&
                    <QuickEdit as="select" size="sm" htmlSize={3}
                      name="condition"
                      options={[ 
                        { key: 'new', value: 'New' }, 
                        { key: 'ref', value: 'Refurb' },
                        { key: 'use', value: 'Used' }
                      ]}
                      onChange={ this.updateRow }
                      value={ row.condition }>
                        <Badge variant="secondary" bg="secondary" className="clickable">
                          { row.condition && row.condition.toUpperCase() }
                        </Badge>
                    </QuickEdit>
                  }
                  <QuickEdit type="quicksearch" size="sm"
                    htmlSize={ 10 }
                    name="product"
                    placeholder="Search Products"
                    defaultValue={(product.model 
                      && `${ product.model } | ${ product.title }`) 
                      || ''
                    }
                    renderOption={ row => <SearchProductResults row={ row } /> }
                    customOption={{ 'Add New': this.addProduct }}
                    onSearch={ this.handleSearch }
                    onChange={ this.updateProduct } >
                      { product.model &&
                        <strong>{ product.vendor } { product.model }</strong>
                      }
                      { !product.model &&
                        <strong className="clickable">Link to Product</strong>
                      }
                  </QuickEdit>                   
                </div>
                { product.image &&
                  <div className="image-carousel float-left">
                    <div className="image">
                      <Image 
                        fluid 
                        src={ product.image[0] } 
                        className="clickable"
                        onClick={() => this.showImages(product.image)} />              
                    </div>
                  </div>
                }
                <div className="title">
                  <small className="text-muted">
                    { product.title }
                  </small>
                </div>
                <div className="availability">
                  { product && product.model &&
                  <StockWarehouse 
                    listings={ listings }
                    product={ product }
                    stock={ product.stock }
                    editListing={ editListing }
                    editStock={ () => this.editStock(product) } />     
                  }
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={ 12 }>
                { !!this.state.orphanOrders.length &&
                  <Alert variant="warning" bg="warning">
                    <Alert.Heading>Orphan Order(s)</Alert.Heading>              
                    <p>There are { this.state.orphanOrders.length }x orphan
                        order(s) associated with this marketplace listing </p>
                  </Alert>
                }
              </Col>
            </Row>
            { !!this.state.orphanOrders.length 
              && this.state.orphanOrders.map((order, idx) => {
                const items = (order.item || [ ]).filter(
                  item => item.channelId === row.childId
                );
              
                return (
                  <Row key={ idx } className="orphan-order">
                    <Col sm={ 2 }>
                      <FaRegCalendarAlt />          
                      { formatDate(order.ordered, 'M/D/YY') }
                    </Col>
                    <Col sm={ 4 }>
                      { order.number }
                    </Col>
                    <Col sm={ 2 }>
                      <FaBoxes />
                      { items.length && items[0].quantity }
                    </Col>
                    <Col sm={ 2 }>
                      <FaFileInvoiceDollar />
                      { items.length && items[0].price }
                    </Col>
                    <Col sm={ 2 }>
                      <Badge
                        className="clickable" 
                        onClick={ () => this.linkOrder(idx) } 
                        variant={ !!order.product ? 'success' : 'secondary' }
                        bg={ !!order.product ? 'success' : 'secondary' }>
                          { !!order.product ? 'Link' : 'Unlinked' }
                      </Badge>
                    </Col>
                  </Row>
                );
              })
            }
          </Container>
        </div>
      </form>
    );
  }
}

export { ListingLink };
