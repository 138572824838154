import React from 'react';
import { FaBoxes, FaLongArrowAltRight } from 'react-icons/fa';
import { Container } from 'react-bootstrap';
import { getChannelIcon, getStockStyles, getStockTypeMap, formatDate } from './AllThing';
import { MoreInfo } from './MoreInfo';
import { Listing } from './Listing';

const StockWarehouse = ({
  stock = {},
  product,
  listings,
  editListing,
  editStock
}) => {
  
  //const compact = 'compact' in props;  
  const listingMarkup = [ ];
  const mapMarkup = [ ];
  const stockMarkup = [ ];
  const markup = [ ];
  const channelMarkup = [ ];
  const channelMap = { };
  
  const stockFormat = getStockStyles();
  const stockMap = getStockTypeMap();
  const conditionArr = Object.keys(stockMap);
  
  let lastStockType = false;
  let lastFormat = 'text-info';
  
  for (let i = 0; i < conditionArr.length; i++) {
    const condition = conditionArr[i];
    const stockType = stockMap[ condition ];
    const format = stockFormat[ condition ];
    const stockCount = (stock[ condition ] || { }).count || 0;
    
    if ((lastStockType && lastStockType !== stockType) 
      || i === conditionArr.length) {
      
        if (stockMarkup.length) {
          mapMarkup.push(
            <span key={`mapMarkup-${i}`}>
              { stockMarkup.map((m, index) => 
                <React.Fragment key={index}>{m}</React.Fragment>
              )}
            </span>
          );  
        } else if (listingMarkup.length) {
          mapMarkup.push(
            <span key={`listingMarkup-${i}`}>
              <span className={`stock text-danger`}>
                { lastStockType.toUpperCase() } (0)
              </span>
            </span>
          );  
        }
        
        if (mapMarkup.length) {
          markup.push(
            <span className="stock-map" key={`markup-${i}`}>
              <FaBoxes className={ `stock-icon ${ lastFormat }` } />
              <span className="clickable" onClick={ editStock }>
                { mapMarkup.map((m, index) => 
                  <React.Fragment key={index}>{m}</React.Fragment>
                )}
              </span>
              { !!listingMarkup.length &&
                <span className={ `listings clickable ${ lastFormat }` }>
                  <FaLongArrowAltRight className="icon-longarrowright" />
                  <MoreInfo
                    body={ 
                      <Container>
                        { listingMarkup.map((m, index) => 
                          <React.Fragment key={index}>{m}</React.Fragment>
                        )}
                      </Container>
                    }>
                        <span className="snug nowrap">
                          { channelMarkup.map((c, index) => 
                            <React.Fragment key={index}>{c}</React.Fragment>
                          )}
                        </span>
                  </MoreInfo>
                </span>
              }
            </span>          
          );
        }
        
        channelMarkup.length = 0;
        listingMarkup.length = 0;
        mapMarkup.length = 0;
        stockMarkup.length = 0;
    }
    
    // If we are at the end of the condition array and have already rendered
    // everything, we're done.
    if (i === conditionArr.length) continue;
    
    if (stockCount) {
      stockMarkup.push(
        <span 
          className={`stock ${ stockCount > 0 ? format : 'text-danger'}`} 
          key={ condition }>
            { condition.toUpperCase() } ({ stockCount })
        </span>
      );
    }

    Object.entries(listings || { }).map(([key, listing]) => {
      listing.key = key;
    
      if ('active' in listing && !listing.active) return;
      
      if ((listing.condition.toLowerCase() === condition 
        && (!listing.fba || !listing.fba.active)) 
        || (condition === 'fba' && listing.fba && listing.fba.active)) {
  
          channelMap[ stockType ] = channelMap[ stockType ] || { };
          
          if (!(listing.channel in channelMap[ stockType ])) {
            channelMap[ stockType ][ listing.channel ] = true;
            channelMarkup.push(
              <span key={ listing.key }>
                { getChannelIcon(listing.channel) }
              </span>
            );
          }
    
          listingMarkup.push(
            <Listing 
              editListing={ editListing } 
              product={ product } 
              row={ listing } 
              key={ key } />
          );
      }    
    });
    
    lastFormat = format;
    lastStockType = stockType;
  }
  
  if (!markup.length) {
    return (
      <span className="stock-map">
        <FaBoxes className={ `stock-icon text-danger` } />
        <span className="clickable" onClick={ editStock }>
          <span className="stock text-danger">No Stock</span>
        </span>
      </span>          
    );
  } else {
    return markup;
  }  
}

export { StockWarehouse };
